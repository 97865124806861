<template>
  <div class="section">
    <navigation></navigation>
    <div class="mt-8 d-flex flex-wrap">
        <router-link class="grower-profile" to="grower-profile">
            <v-card class="ma-5" flat tile>
                <v-img
                alt="La Finca Tree"
                class="shrin"
                contain
                src="../assets/default-photo.jpg"
                transition="scale-transition"
                width="150"
                height="150"
                text="Lorem Ipsum"
                />
                <v-card-text class="text-center">Juan Dela Cruz</v-card-text>            
            </v-card>
        </router-link>
        <router-link class="grower-profile" to="grower-profile">
            <v-card class="ma-5" flat tile>
                <v-img
                alt="La Finca Tree"
                class="shrin"
                contain
                src="../assets/default-photo.jpg"
                transition="scale-transition"
                width="150"
                height="150"
                text="Lorem Ipsum"
                />
                <v-card-text class="text-center">Juan Dela Cruz</v-card-text>            
            </v-card>
        </router-link>
        <router-link class="grower-profile" to="grower-profile">
            <v-card class="ma-5" flat tile>
                <v-img
                alt="La Finca Tree"
                class="shrin"
                contain
                src="../assets/default-photo.jpg"
                transition="scale-transition"
                width="150"
                height="150"
                text="Lorem Ipsum"
                />
                <v-card-text class="text-center">Juan Dela Cruz</v-card-text>            
            </v-card>
        </router-link>
        <router-link class="grower-profile" to="grower-profile">
            <v-card class="ma-5" flat tile>
                <v-img
                alt="La Finca Tree"
                class="shrin"
                contain
                src="../assets/default-photo.jpg"
                transition="scale-transition"
                width="150"
                height="150"
                text="Lorem Ipsum"
                />
                <v-card-text class="text-center">Juan Dela Cruz</v-card-text>            
            </v-card>
        </router-link>
        <router-link class="grower-profile" to="grower-profile">
            <v-card class="ma-5" flat tile>
                <v-img
                alt="La Finca Tree"
                class="shrin"
                contain
                src="../assets/default-photo.jpg"
                transition="scale-transition"
                width="150"
                height="150"
                text="Lorem Ipsum"
                />
                <v-card-text class="text-center">Juan Dela Cruz</v-card-text>            
            </v-card>
        </router-link>
        <router-link class="grower-profile" to="grower-profile">
            <v-card class="ma-5" flat tile>
                <v-img
                alt="La Finca Tree"
                class="shrin"
                contain
                src="../assets/default-photo.jpg"
                transition="scale-transition"
                width="150"
                height="150"
                text="Lorem Ipsum"
                />
                <v-card-text class="text-center">Juan Dela Cruz</v-card-text>            
            </v-card>
        </router-link>
    </div>
  </div>
</template>

<script>
import Navigation from './Navigation.vue'
export default {
  components: { Navigation },

}
</script>

<style>
    .section{padding: 5px 65px 5px 65px;}
    .grower-profile{text-decoration: none;}

    @media only screen and (max-width: 600px) {
      .section{
        padding: 0px;
      }
    }
</style>